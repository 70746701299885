@use '../utils/breakpoints' as *;

.primary-menu {
  --space: 24px;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space);

  @include breakpoint(large) {
    --space: 30px;
  }

  > li {
    position: relative;
    &:hover {
      svg {
        transform: rotate(180deg);
        fill: var(--light-blue);
      }
    }

    > a {
      padding: 10px 0;
    }

    > ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
      list-style: none;
      margin: 0;
      padding: 30px 15px 5px 15px;
      position: absolute;
      top: 100%;
      left: calc(var(--space) * -1);
      background: var(--hard-blue);
      min-width: 220px;
      border-radius: 0;
      box-shadow: var(--shadow);
      opacity: 0;
      pointer-events: none;
      transition: opacity var(--speed-fast);

      a {
        color: var(--white);
        font-size: 18px;
        font-weight: 600;
        padding: 8px 25px 8px 0;
        border-bottom: 1px solid #1c2a4d;
        transition: color var(--speed);
        &::before {
          display: none;
        }

        &:hover {
          color: var(--light-blue);
        }
      }
    }

    &:hover,
    &:focus-within {
      > ul {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  a {
    color: var(--white);
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: color var(--speed);
    font-weight: 600;
    font-size: 18px;
    position: relative;
    &::before {
      content: '';
      display: block;
      height: 1px;
      width: calc(100% - 30px);
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) scaleX(0);
      transition: all 0.5s;
      background: linear-gradient(
        270deg,
        rgba(12, 12, 18, 0),
        #0094ff 48.96%,
        transparent 100%,
        rgba(12, 12, 18, 0) 0
      );
    }
    svg {
      transition: all var(--speed);
    }

    &:hover {
      color: var(--light-blue);
      transition: fill var(--speed-fast);
      &::before {
        transform: translateX(-50%) scaleX(1);
      }
    }

    svg {
      fill: var(--white);
      margin-left: 5px;
    }
  }
}

.slide-menu {
  list-style: none;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  margin: 0;

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    text-align: center;
    color: var(--white);
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    padding: 12px;
    border-bottom: 1px solid #1c2a4d;
    @include breakpoint(small up) {
      font-size: 18px;
    }
    @include breakpoint(medium up) {
      border-bottom: none;
    }

    svg {
      fill: var(--white);
      transition: transform var(--speed);
    }

    &:hover {
      color: var(--light-grey);
    }

    &.is-active {
      color: var(--light-blue);
      svg {
        transform: rotate(90deg);
      }
    }
    &.now-position {
      position: relative;
      color: var(--light-blue);
      svg {
        fill: var(--light-blue);
      }
      &::before {
        content: '';
        display: block;
        height: 1px;
        width: calc(100% - 30px);
        position: absolute;
        bottom: 0;
        left: 50%;
        background: linear-gradient(
          270deg,
          rgba(12, 12, 18, 0),
          #0094ff 48.96%,
          transparent 100%,
          rgba(12, 12, 18, 0) 0
        );
        transform: translateX(-50%) scaleX(1);
      }
    }
  }

  ul {
    display: none;
    list-style: none;
    margin: 0;
    padding: 10px 10px 0 30px;

    li:not(:last-child) {
      margin-bottom: 6px;
    }

    a {
      font-size: 14px;
      padding: 0;
      font-weight: 400;
      border: none;
      @include breakpoint(small up) {
        font-size: 18px;
      }
    }
  }
}
