@use '../utils/breakpoints' as *;
@use '../utils/no-first-last-margin' as *;

.callout {
  --x-space: 20px;
  --y-space: 24px;
  --l-space: 16px;
  background: var(--white);
  border-radius: var(--radius);
  box-shadow: var(--shadow-2);
  padding: var(--y-space) var(--x-space) var(--y-space)
    calc(var(--x-space) + var(--l-space));
  position: relative;

  @include no-first-last-margin;

  @include breakpoint(medium) {
    --x-space: 32px;
    --y-space: 46px;
    --l-space: 24px;
  }

  &:before {
    content: '';
    position: absolute;
    top: var(--y-space);
    bottom: var(--y-space);
    left: var(--x-space);
    border-left: 1px solid var(--secondary-color);
  }
}
