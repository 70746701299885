.text-center {
  text-align: center;
}

.hide {
  display: none !important;
}

.d-none {
  display: none;
}
