@use '../utils/breakpoints' as *;

.er404 {
  background-color: var(--hard-blue);
  position: relative;
  padding: 12px 0 26px;
  @include breakpoint(small up) {
    padding: 12px 0 76px;
  }

  & + .subscribe .wave {
    display: none;
  }
  &-icon {
    position: absolute;
    display: none;
    &:first-child {
      top: 50px;
      right: 7%;
    }
    &:nth-child(2) {
      bottom: 5%;
      left: 3%;
      width: 62px;
    }

    @include breakpoint(small up) {
      display: block;
    }
  }
  &-inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 80px;
  }

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    color: var(--white);
    text-align: center;
  }

  &-ttl {
    font-weight: 700;
    font-size: 22px;
    @include breakpoint(small up) {
      font-size: 36px;
    }
  }

  &text {
    font-size: 14px;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 4px;
    text-align: center;
  }
}
