@use 'settings' as *;
@use '../utils/breakpoints' as *;

:root {
  // Fonts
  --body-font-family: 'Raleway', sans-serif;
  --body-font: 600 14px/1.4 var(--body-font-family);
  --small-font-size: 14px;

  --title-font-family: 'Raleway', sans-serif;
  --h1-font: 700 28px/1.3 var(--title-font-family);
  --h2-font: 700 22px/1.3 var(--title-font-family);
  --h3-font: 600 16px/1.3 var(--title-font-family);
  --h4-font: 600 14px/1.3 var(--title-font-family);
  --h5-font: 600 12px/1.3 var(--title-font-family);
  --h6-font: 500 10px/1.3 var(--title-font-family);

  // Palette
  --white: #fff;
  --light-grey: #d2d7e1;
  --dark-grey: #50596a;
  --light-blue: #11a9fe;
  --blue: #1a2996;
  --dark-blue: #19227d;
  --hard-blue: #030f2d;
  --black: #000;
  --on-white: var(--black);
  --on-light-grey: var(--black);
  --on-medium-grey: var(--black);
  --on-dark-grey: var(--white);
  --on-black: var(--white);

  --primary-color: #186baa;
  --primary-dark-color: #092b82;
  --primary-light-color: #384dff;
  --on-primary-color: var(--white);
  --on-primary-dark-color: var(--white);
  --on-primary-light-color: var(--white);

  --secondary-color: #f07300;
  --secondary-dark-color: #f09000;
  --secondary-light-color: #f9b54f;
  --on-secondary-color: var(--white);
  --on-secondary-dark-color: var(--white);
  --on-secondary-light-color: var(--white);

  --surface: #eef4fe;
  --surface-2: #afb6cc;
  --on-surface: var(--dark-grey);
  --on-surface-2: var(--black);

  --red: #cd0f0f;
  --red-light: #fff5f5;
  --on-red: var(--white);
  --on-red-light: var(--black);

  --green: #42c777;
  --green-light: #f5fff9;
  --on-green: var(--white);
  --on-green-light: var(--black);

  --body-color: var(--dark-grey);
  --body-background: #eef4fe;

  // Shadows
  --shadow: 0 3px 8px rgba(2, 9, 24, 0.56);
  --shadow-2: 4px 4px 20px rgba(9, 34, 101, 0.08);
  --shadow-3: -2px 2px 16px rgba(21, 21, 21, 0.12);

  // Radius
  --radius: 10px;
  --radius-small: 4px;

  // Sizes
  --gap: 40px;
  --row-gap: 40px;
  --column-gap: 40px;
  --content-width: #{map-get($content-width, small)}px;
  --container-space: 10px;
  --container-width: calc(var(--content-width) + var(--container-space) * 2);
  --section-space: 56px;

  // Transitions
  --speed: 0.4s;
  --speed-fast: 0.15s;

  // Responsive
  @each $name, $val in $content-width {
    @if $name != 'xsmall' {
      @include breakpoint($name) {
        --content-width: #{$val}px;
      }
    }
  }

  @include breakpoint(medium) {
    --body-font: 400 18px/1.3 'Raleway', sans-serif;
    --small-font-size: 14px;

    --h1-font: 700 44px/1.3 var(--title-font-family);
    --h2-font: 700 36px/1.3 var(--title-font-family);
    --h3-font: 600 34px/1.3 var(--title-font-family);
  }

  @include breakpoint(xlarge) {
    --h1-font: 700 44px/1.3 var(--title-font-family);
    --h2-font: 700 36px/1.3 var(--title-font-family);
    --h3-font: 700 26px/1.3 var(--title-font-family);
  }
}
