@use '../utils/breakpoints' as *;

.bread {
  --gap: 5px;
  list-style: none;
  padding: 12px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--gap);
  color: var(--light-grey);
  font-size: 14px;

  a {
    color: var(--light-blue);
    display: flex;
    align-items: center;
    gap: var(--gap);
    text-decoration: none;
    transition: color var(--speed-fast);

    &:hover {
      color: var(--light-blue);
    }
  }
}
