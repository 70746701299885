@use '../utils/no-first-last-margin' as *;
@use '../utils/breakpoints' as *;

body {
  display: flex;
  flex-direction: column;
}

.section {
  margin-top: var(--section-space);
  margin-bottom: var(--section-space);

  .inner {
    padding-top: var(--section-space);
    padding-bottom: var(--section-space);
  }

  &.no-out-space {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.no-top-space {
    margin-top: 0;
  }

  &.no-btm-space {
    margin-bottom: 0;
  }

  &.bg {
    background-color: var(--hard-blue);
  }
}

.container {
  max-width: var(--container-width);
  padding-left: var(--container-space);
  padding-right: var(--container-space);
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    max-width: 540px;
  }
}

.container,
.content {
  @include no-first-last-margin;
}

.content {
  p {
    font-size: 14px;
  }

  &-main {
    padding: 0 15px;
  }

  &-aside {
    display: flex;
    flex-direction: column;
    position: relative;

    @include breakpoint(medium up) {
      display: grid;
      grid-template-columns: 75% 25%;
    }

    .aside {
      padding: 0 15px;
      width: 100%;
      display: none;
      @include breakpoint(medium up) {
        display: block;
        align-self: flex-start;
        top: 105px;
        position: sticky;
      }
    }
  }
}
