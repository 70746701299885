* {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

main,
section,
nav {
  display: block;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: 800;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 1em;
  line-height: 1.3;
}

label {
  display: block;
}

button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: button;

  &:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
}

button,
select {
  text-transform: none;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}
