.content {
  :where(ul, ol):not([class]) {
    --space: 0.5em;
    list-style: none;
    margin: 1em 0;
    padding: 0;
    font-size: 16px;
    line-height: 1.4em;

    li {
      position: relative;

      &:not(:last-child) {
        margin-bottom: var(--space);
      }
    }

    :where(ul, ol) {
      margin: var(--space) 0 var(--space) 1em;
    }
  }

  ul:not([class]) {
    list-style: none;
    padding: 0;
    li {
      padding-left: 14px;
      position: relative;
      &:before {
        background: linear-gradient(320.66deg, #f07300 14.75%, #ffb017 84.81%);
        position: absolute;
        top: 7px;
        left: 0;
        content: '';
        border-radius: 50%;
        display: block;
        width: 6px;
        height: 6px;
      }
    }
  }

  ol:not([class]) {
    --space: 19px;
    counter-reset: ol;
    counter-set: ol;
    margin: var(--space) 0;

    > li {
      counter-increment: ol;
    }

    li {
      padding-left: 35px;

      &:before {
        content: counter(ol);
        color: var(--primary-color);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 27px;
        height: 27px;
        border: 1px solid var(--primary-color);
        border-radius: 50%;
        position: absolute;
        top: 0.7em;
        left: 0;
        transform: translateY(-50%);
      }

      > ol {
        counter-reset: ol-2;
        counter-set: ol-2;

        > li {
          counter-increment: ol-2;

          &:before {
            content: counter(ol) '.' counter(ol-2);
            font-size: 0.8em;
          }
        }
      }
    }
  }
}
