.rate {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 18px;
  color: var(--white);

  &-stars {
    height: 17px;
    aspect-ratio: 88/17;
    background: url('../../img/rating_off.webp') repeat-x top left / contain;
    position: relative;
    > span {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: url('../../img/rating_on.webp') repeat-x top left / contain;
    }
  }
}
