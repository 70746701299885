@use '../utils/breakpoints' as *;

.header {
  position: sticky;
  top: 0;
  background: var(--hard-blue);
  z-index: 12;
  padding: 15px 0;

  &-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  &-logo {
    display: flex;
    transition: opacity var(--speed-fast);

    :where(img, svg) {
      @include breakpoint(medium down) {
        max-height: 40px;
        width: auto;
      }
    }

    //.off-canvas-open & {
    //  opacity: 0;
    //  pointer-events: none;
    //}
  }

  &-nav,
  .search {
    @include breakpoint(medium down) {
      display: none !important;
    }
  }
  &-lang {
    @include breakpoint(medium down) {
      order: -1;
    }
  }

  &-act {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  &-toggle {
    @include breakpoint(large) {
      display: none;
    }

    &-btn {
      display: flex;

      svg {
        path {
          transition: all var(--speed);
          transform-origin: center;
        }
      }

      &.is-active {
        svg {
          path {
            &:nth-child(1) {
              transform: translate(-4px, 4px) rotate(45deg);
            }

            &:nth-child(2) {
              opacity: 0;
            }

            &:nth-child(3) {
              transform: translate(-4px, -4px) rotate(-45deg);
            }
          }
        }
      }
    }
  }
}
