@use '../utils/breakpoints' as *;
@use '../settings/settings';

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--row-gap) var(--column-gap);

  > * {
    width: 100%;
  }

  @for $i from 1 through map-get(settings.$grid-cols, xsmall) {
    &.col-#{$i} {
      > * {
        width: calc((100% / $i) - var(--column-gap) + (var(--column-gap) / $i));
      }
    }
  }

  @each $bp, $val in settings.$breakpoints {
    @if ($bp != 'xsmall') {
      @include breakpoint($bp) {
        @for $i from 1 through map-get(settings.$grid-cols, $bp) {
          &.#{$bp}-col-#{$i} {
            > * {
              width: calc(
                (100% / #{$i}) - var(--column-gap) + (var(--column-gap) / $i)
              );
            }
          }
        }
      }
    }
  }

  &.expanded {
    > * {
      flex: 1 1 auto;
    }
  }

  $align: (
    top: flex-start,
    center: center,
    bottom: flex-end,
  );

  $justify: (
    left: flex-start,
    center: center,
    right: flex-end,
    between: space-between,
    around: space-around,
  );

  @each $dir, $val in $align {
    &:where(.align-#{$dir}) {
      align-items: $val;
    }

    @each $bp, $px in settings.$breakpoints {
      @if ($bp != 'small') {
        @include breakpoint($bp) {
          &.#{$bp}-align-#{$dir} {
            align-items: $val;
          }
        }
      }
    }
  }

  @each $dir, $val in $justify {
    &:where(.justify-#{$dir}) {
      justify-content: $val;
    }

    @each $bp, $px in settings.$breakpoints {
      @if ($bp != 'small') {
        @include breakpoint($bp) {
          &.#{$bp}-justify-#{$dir} {
            justify-content: $val;
          }
        }
      }
    }
  }
}
