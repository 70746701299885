@use '../utils/headers' as *;

// In this file you should wright only base styles for tags!

::-webkit-scrollbar {
  background: var(--primary-dark-color);
  width: 10px;
  height: 10px;

  &:horizontal {
    border-radius: 5px;
  }

  &-thumb {
    background: var(--primary-color);
    border: 2px solid var(--primary-dark-color);
    border-radius: 5px;
  }
}

body {
  font: 600 14px/1.4 var(--body-font-family);
  color: var(--body-color);
  background: var(--body-background);
}

a {
  color: var(--primary-dark-color);
  text-decoration: underline;
  transition: color var(--speed-fast);

  &:hover {
    color: var(--primary-color);
  }
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 1em 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@include all-headers {
  margin: 0 0 1rem;

  .content & {
    margin: 16px 0;
  }
}

@for $i from 1 through 6 {
  h#{$i},
  .h#{$i} {
    font: var(--h#{$i}-font);
    color: var(--primary-dark-color);
  }
}

h3:not([class]) {
  padding-left: 11px;
  border-left: 3px solid #092b82;
}

small,
.small {
  font-size: var(--small-font-size);
}
