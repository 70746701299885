.lang-sw {
  --space: 8px;
  width: 62px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    fill: var(--white);
  }

  img {
    width: 30px;
  }

  &:hover,
  &:focus-within {
    .lang-sw-rest {
      opacity: 1;
      pointer-events: all;
    }
  }

  &-cur {
    display: flex;
    align-items: center;
    padding: 4px 0;
  }

  &-rest {
    display: grid;
    gap: var(--space);
    padding: var(--space);
    position: absolute;
    top: 100%;
    left: 0;
    background: var(--hard-blue);
    box-shadow: var(--shadow);
    border-radius: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--speed-fast);

    a {
      width: 32px;
      height: 32px;
      display: flex;
    }
  }
}
