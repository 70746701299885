@use '../utils/breakpoints' as *;

.section-title {
  font: var(--h2-font);
  margin-bottom: 24px;
  color: var(--primary-dark-color);

  @include breakpoint(large) {
    margin-bottom: 32px;
  }
}
