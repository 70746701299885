@use '../utils/breakpoints' as *;

.news {
  &-ttl {
    margin-bottom: 36px;
  }

  &-img {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      display: block;
      @include breakpoint(small down) {
        max-height: 320px;
        object-fit: cover;
      }
    }
  }

  &-hover {
    opacity: 0;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(195, 202, 228, 0.34);
    backdrop-filter: blur(7px);
    transform: translateZ(0);
    z-index: 3;
    width: 100%;
    height: 100%;
  }

  &-inner {
    &.swiper{
      padding: 0 20px 30px;
      margin: 0 -20px;
    }
    @include breakpoint(large up) {
      padding-bottom: 0;
    }
  }

  &-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    z-index: 4;
    transform: translate(-50%, -50%);
  }

  &-item {
    background: #edf3fa;
    box-shadow:
      -4px -2px 21px hsla(0, 0%, 100%, 0.83),
      6px 3px 15px rgba(136, 165, 191, 0.4);
    border-radius: var(--radius-small);
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;

    &:hover {
      .news-hover {
        opacity: 1;
      }
      .news-btn {
        opacity: 1;
      }
    }
  }

  &-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 12px;
    font-weight: 300;
    time {
      color: #b1b4b9;
    }
  }

  &-author {
    display: flex;
    align-items: center;
    img {
      border: 2px solid var(--white);
      border-radius: 50%;
      width: 31px;
      height: 31px;
      background: var(--white);
      overflow: hidden;
      margin-right: 10px;
    }
  }

  &-link {
    display: block;
    padding: 16px 12px;
    font-weight: 700;
    font-size: 18px;
    text-decoration: none;
    color: var(--dark-grey);
    transition: all 0.3s;
    &:hover {
      color: var(--primary-dark-color);
    }
  }

  .swiper-pagination-bullet {
    border: 1px solid #092b82;
    background: 0 0;
    opacity: 1;
    transition: 0.4s;
  }
  .swiper-pagination-bullet-active {
    background: #092b82;
  }
}
