.button {
  display: inline-flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 12px 28px;
  color: var(--on-primary-color);
  background: linear-gradient(95.01deg, #4c31f0, #1e3ce8 99.93%);
  border-radius: var(--radius-small);
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  border: none;
  line-height: 20px;

  &:is(:hover, :focus) {
    color: var(--on-primary-color);
    background: var(--primary-light-color);
    outline: 0;
  }

  &:is(a) {
    text-decoration: none;
  }

  &.round {
    border-radius: 50px;
  }

  &.hollow {
    background: none;
    color: var(--dark-blue);
    border: 1px solid var(--blue);

    &:is(:hover, :focus) {
      color: var(--white);
      background: linear-gradient(95.01deg, #4c31f0, #1e3ce8 99.93%);
      border-color: transparent;
    }
  }

  &.secondary {
    background: linear-gradient(320.66deg, #0076b8 14.75%, #11a9fe 84.81%);
    border: none;
    padding: 8px 16px;
    color: var(--white);
    font-size: 14px;

    &:is(:hover, :focus) {
      background: linear-gradient(320.66deg, #11a9fe 14.75%, #0076b8 84.81%);
    }

    &.hollow {
      background: none;
      color: var(--secondary-color);

      &:is(:hover, :focus) {
        color: var(--secondary-light-color);
      }
    }
  }
  &.orange {
    &.hollow {
      font-size: 14px;
      text-transform: capitalize;
      font-weight: 700;
      background: none;
      color: var(--dark-blue);
      border: 1px solid var(--blue);
      width: 100%;

      &:is(:hover, :focus) {
        background: 0 0;
        border-color: #f07300;
        color: #f07300;
      }
    }
  }

  &.white {
    &.hollow {
      background: none;
      border-color: #bac1ff;
      color: var(--white);

      &:is(:hover, :focus) {
        color: var(--white);
        background: linear-gradient(95.01deg, #4c31f0, #1e3ce8 99.93%);
        border-color: transparent;
      }
    }
  }

  &.play {
    background: linear-gradient(95.01deg, #4c31f0, #1e3ce8 99.93%);
    border: none;
    font-size: 16px;
    color: var(--white);

    &:is(:hover, :focus) {
      background: #384dff;
      color: var(--primary-dark-color);
    }

    &.hollow {
      background: none;
      color: var(--white);

      &:is(:hover, :focus) {
        color: var(--light-grey);
      }
    }
  }

  &.expanded {
    display: flex;
    width: 100%;
  }

  &.invert {
    background: var(--primary-dark-color);
    border: 1px solid var(--primary-dark-color);
    color: var(--white);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: color, background-color, opacity;
    padding: 8px 16px;
    min-width: 130px;
    font-size: 16px;

    &:is(:hover, :focus) {
      background: 0 0;
      color: var(--primary-dark-color);
    }
  }
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 1rem;

  &.expanded {
    .button {
      flex: 1 0 320px;
    }
  }
}
