@use '../utils/breakpoints' as *;

.filter {
  background: var(--hard-blue);
  padding: 50px 0 30px;
  position: relative;
  margin-right: 15px;
  margin-left: 15px;
  @include breakpoint(small up) {
    margin-right: 0;
    margin-left: 0;
  }

  &-icon {
    position: absolute;
    width: 58px;
    top: 85px;
    left: 2%;
    z-index: 0;
    display: none;
    @include breakpoint(small up) {
      display: block;
    }
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 32px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1440' height='30' viewBox='0 0 1440 30' fill='none'%3E%3Cg clip-path='url(%23frames/dark_bottom_264606_clip0_1318_16107)'%3E%3Cpath d='M0 -424H1440V11C1440 11 1000.67 30.5212 719 30.5C438.11 30.4789 0 11 0 11V-424Z' fill='%23030F2D'%3E%3C/path%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='frames/dark_bottom_264606_clip0_1318_16107'%3E%3Crect width='1440' height='30' fill='white'%3E%3C/rect%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E")
      no-repeat center center / contain;
    background-size: cover;
    top: 100%;
    position: absolute;
    z-index: 10;
    @include breakpoint(medium down) {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='320' height='10' viewBox='0 0 320 10' fill='none'%3E%3Cg clip-path='url(%23frames/dark_bottom-mobile_495168_clip0_1318_16973)'%3E%3Cpath d='M0 -387H320V5C320 5 222.21 10.0122 159.5 10C97.1808 9.98785 0 5 0 5V-387Z' fill='%23030F2D'%3E%3C/path%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='frames/dark_bottom-mobile_495168_clip0_1318_16973'%3E%3Crect width='320' height='10' fill='white'%3E%3C/rect%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }
  }

  &-wrap {
    position: relative;
    overflow: hidden;
    padding-bottom: 30px;
  }

  &-bg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 0;
    img {
      width: 100%;
      height: auto;
    }
    display: none;
    @include breakpoint(small up) {
      display: block;
    }
  }

  &-top {
    position: relative;
  }

  &-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: #fff;
    opacity: 0.5;
    z-index: 8;
    svg {
      animation-name: spin;
      animation-duration: 900ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      stroke: rgb(9, 43, 130);
    }
  }

  &-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    font-size: 22px;
    color: var(--white);
    margin-bottom: 16px;
    button {
      color: var(--light-grey);
      font-size: 14px;
      text-transform: uppercase;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-menu {
    overflow-x: auto;
    margin-bottom: 25px;
    padding-bottom: 3px;
  }

  &-list {
    display: flex;
    gap: 16px;
    flex-wrap: nowrap;
    width: min-content;
  }

  &-list-btn {
    background: linear-gradient(320.66deg, #0076b8 14.75%, #11a9fe 84.81%);
    padding: 1px;
    border-radius: var(--radius-small);
    overflow: hidden;
    button {
      border-radius: var(--radius-small);
      padding: 10px 22px;
      transition: 0.4s;
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #fff;
      background: var(--hard-blue);
      display: flex;
      gap: 10px;
      align-items: center;
      .close {
        display: none;
        width: 18px;
        height: 18px;
        background: var(--white);
        border-radius: 2px;
        justify-content: center;
        align-items: center;
        svg {
          fill: var(--black);
        }
      }

      &.is-active {
        background: linear-gradient(320.66deg, #0076b8 14.75%, #11a9fe 84.81%);
        .close {
          display: flex;
        }
      }

      &:hover {
        background: linear-gradient(320.66deg, #0076b8 14.75%, #11a9fe 84.81%);
      }
    }
  }

  &-ttl {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    position: relative;
    font-size: 22px;
    @include breakpoint(small up) {
      font-size: 36px;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      background: linear-gradient(
        270deg,
        rgba(12, 12, 18, 0),
        #0094ff 48.96%,
        transparent 100%,
        rgba(12, 12, 18, 0) 0
      );
      height: 1px;
      flex: 1;
      @include breakpoint(medium down) {
        display: none;
      }
    }
  }

  &-results {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    @include breakpoint(large up) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #041338;
    box-shadow: 0 4px 6px #000b25;
    border-radius: var(--radius-small);
    overflow: hidden;

    &-top {
      position: relative;
    }
    &-hover {
      opacity: 0;
      transition: all 0.3s;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(195, 202, 228, 0.34);
      backdrop-filter: blur(7px);
      transform: translateZ(0);
      z-index: 3;
      width: 100%;
      height: 100%;
    }

    &-link {
      position: absolute;
      top: 50%;
      left: 50%;
      opacity: 0;
      z-index: 4;
      transform: translate(-50%, -50%);
      transition: all 0.3s;
      min-width: 156px;
    }

    &-name {
      color: var(--white);
      text-align: center;
      padding: 14px 4px;
      font-weight: 700;
      font-size: 18px;
    }

    &:hover {
      .filter-card-hover {
        opacity: 1;
      }

      .filter-card-link {
        opacity: 1;
      }
    }
  }

  &.no-filter-top {
    .filter-header {
      display: none;
    }
  }
}
