@use '../utils/breakpoints' as *;
.hero {
  background: var(--hard-blue);
  padding: 32px 0 30px;
  position: relative;
  margin-right: 15px;
  margin-left: 15px;
  @include breakpoint(small up) {
    margin-right: 0;
    margin-left: 0;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 32px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1440' height='30' viewBox='0 0 1440 30' fill='none'%3E%3Cg clip-path='url(%23frames/dark_bottom_264606_clip0_1318_16107)'%3E%3Cpath d='M0 -424H1440V11C1440 11 1000.67 30.5212 719 30.5C438.11 30.4789 0 11 0 11V-424Z' fill='%23030F2D'%3E%3C/path%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='frames/dark_bottom_264606_clip0_1318_16107'%3E%3Crect width='1440' height='30' fill='white'%3E%3C/rect%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E")
      no-repeat center center / contain;
    background-size: cover;
    top: 100%;
    position: absolute;
    z-index: 10;
    @include breakpoint(medium down) {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='320' height='10' viewBox='0 0 320 10' fill='none'%3E%3Cg clip-path='url(%23frames/dark_bottom-mobile_495168_clip0_1318_16973)'%3E%3Cpath d='M0 -387H320V5C320 5 222.21 10.0122 159.5 10C97.1808 9.98785 0 5 0 5V-387Z' fill='%23030F2D'%3E%3C/path%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='frames/dark_bottom-mobile_495168_clip0_1318_16973'%3E%3Crect width='320' height='10' fill='white'%3E%3C/rect%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }
  }

  &.no-wave {
    &:after {
      display: none;
    }
  }

  h1 {
    color: var(--white);
    max-width: 80%;
    margin-bottom: 8px;
    @include breakpoint(small up) {
      font-size: 44px;
    }
  }

  p {
    margin-bottom: 20px;
  }

  &-content {
    max-width: 90%;
    color: var(--light-grey);
    font-size: 14px;
    font-weight: 300;
    @include breakpoint(small up) {
      font-weight: 600;
    }
  }

  &-wrap {
    background: linear-gradient(320.66deg, #0076b8 14.75%, #11a9fe 84.81%);
    padding: 1px;
    border-radius: var(--radius-small);
    overflow: hidden;
    position: relative;
  }

  &-brand {
    padding: 50px 0 70px;
    position: relative;
    @include breakpoint(small down) {
      padding: 30px 0 50px;
    }

    &-icon {
      position: absolute;
      left: 2%;
      top: 20%;
      width: 60px;
      @include breakpoint(medium down) {
        display: none;
      }
    }

    &-toggle {
      display: none;
      color: var(--white);
      cursor: pointer;
      transition: all var(--speed-fast);
      gap: 5px;
      svg {
        fill: var(--white);
        width: 16px;
        height: 16px;
      }
      &.open {
        color: var(--secondary-color);
        svg {
          fill: var(--secondary-color);
          transform: rotate(-180deg);
        }
      }
      @include breakpoint(xsmall down) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-inner {
      padding: 24px;
      background: #041338;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      height: auto;

      @include breakpoint(small up) {
        padding: 24px 24px 49px 24px;
      }

      @include breakpoint(medium up) {
        display: grid;
        grid-template-columns: 144px auto;
        gap: 60px;
      }

      @include breakpoint(large up) {
        grid-template-columns: 26% auto;
        //height: 626px;
        padding: 24px;
      }
    }

    h1 {
      font-size: 16px;
      margin-bottom: 8px;
      @include breakpoint(small up) {
        font-size: 26px;
      }
    }

    .rate {
      margin-bottom: 5px;
    }

    &-img {
      margin-bottom: 10px;
      a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        border-radius: var(--radius-small);
        overflow: hidden;
      }
    }

    &-box {
      margin-bottom: 10px;
    }

    &-lbl {
      color: var(--light-grey);
      font-weight: 600;
      font-size: 16px;
      display: inline;
      margin-right: 5px;
    }

    &-val {
      font-weight: 600;
      font-size: 16px;
      color: var(--light-blue);
      display: inline;
      a {
        text-decoration: none;
        color: var(---light-blue);
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        transition-property: color, background-color, opacity;
        &:hover {
          color: var(--primary-dark-color);
        }
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: auto;
      max-height: 0;
      transition: all var(--speed-fast);
      overflow: hidden;
      .hero-brand-inner.open & {
        max-height: 2000px;
      }
      @include breakpoint(small up) {
        max-height: none;
      }

      @include breakpoint(medium up) {
        gap: 32px;
      }
    }

    &-label {
      color: var(--light-grey);
      font-weight: 700;
      font-size: 16px;
      display: block;
      margin-bottom: 8px;
      @include breakpoint(small up) {
        font-size: 22px;
      }
      .hero-brand-compare & {
        margin-bottom: 16px;
      }
      .hero-brand-bonus & {
        display: inline;
        margin-bottom: 0;
      }
    }

    &-value {
      color: var(--secondary-color);
      font-weight: 700;
      font-size: 16px;
      display: inline;
      @include breakpoint(small up) {
        font-size: 22px;
      }
    }

    &-items {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &-minus,
    &-plus {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      font-size: 14px;
      line-height: 1.4;
      font-weight: 300;
      color: var(--light-grey);
      @include breakpoint(small up) {
        font-weight: 600;
      }
      &:before {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
      }
    }

    &-minus {
      &:before {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none' class='icon'%3E%3Crect width='18' height='18' rx='3' fill='url(%23minus_288983_paint0_linear_407_4879)'%3E%3C/rect%3E%3Crect x='4' y='10' width='2' height='10' rx='1' transform='rotate(-90 4 10)' fill='white'%3E%3C/rect%3E%3Cdefs%3E%3ClinearGradient id='minus_288983_paint0_linear_407_4879' x1='14.85' y1='15.75' x2='3.6' y2='2.025' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%230076B8'%3E%3C/stop%3E%3Cstop offset='1' stop-color='%2311A9FE'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E")
          no-repeat center center / contain;
      }
    }

    &-plus {
      &:before {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none' class='icon'%3E%3Crect width='18' height='18' rx='3' fill='url(%23plus_251461_paint0_linear_407_4872)'%3E%3C/rect%3E%3Crect x='8' y='4' width='2' height='10' rx='1' fill='white'%3E%3C/rect%3E%3Crect x='4' y='10' width='2' height='10' rx='1' transform='rotate(-90 4 10)' fill='white'%3E%3C/rect%3E%3Cdefs%3E%3ClinearGradient id='plus_251461_paint0_linear_407_4872' x1='14.85' y1='15.75' x2='3.6' y2='2.025' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23F07300'%3E%3C/stop%3E%3Cstop offset='1' stop-color='%23FFB017'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E")
          no-repeat center center / contain;
      }
    }

    &-elements {
      display: flex;
      flex-wrap: nowrap;
      gap: 8px;
      overflow-x: auto;
      .hero-brand-banks & {
        gap: 10px;
        flex-wrap: wrap;
      }
      @include breakpoint(small up) {
        flex-wrap: wrap;
      }
    }

    &-games {
      margin-bottom: 0;
      .hero-wrap {
        min-width: 110px;
        @include breakpoint(small up) {
          min-width: auto;
        }
      }
      @include breakpoint(large up) {
        margin-bottom: 32px;
      }
    }

    &-game {
      display: flex;
      align-items: center;
      border-radius: var(--radius-small);
      gap: 8px;
      background: #030f2d;
      padding: 6px 16px;
      color: #fff;
      min-height: 100%;
      &:before {
        content: '';
        display: block;
        min-width: 10px;
        height: 7px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' viewBox='0 0 10 7' fill='none'%3E%3Cpath d='M1 3.04545L3.97143 6L9 1' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E")
          no-repeat center center / contain;
      }
    }

    &-banks {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #030f2d;
        padding: 4px;
        border-radius: var(--radius-small);
        img {
          border-radius: var(--radius-small);
        }
      }
    }
  }

  &-slot {
    padding: 50px 0 70px;
    position: relative;
    @include breakpoint(small down) {
      padding: 30px 0 50px;
      margin-right: 0;
      margin-left: 0;
    }

    .hero-wrap {
      @include breakpoint(large down) {
        background: none;
      }
    }

    .rate-stars {
      aspect-ratio: 90/17;
      @include breakpoint(xlarge up) {
        //aspect-ratio: 92/17;
      }
    }

    &-icon {
      position: absolute;
      left: 2%;
      bottom: 20%;
      width: 60px;
      @include breakpoint(small down) {
        display: none;
      }
    }

    &-icon-top {
      position: absolute;
      right: 2%;
      top: 15%;
      width: 68px;
      @include breakpoint(small down) {
        display: none;
      }
    }

    &-inner {
      padding: 12px;
      background: #041338;
      border-radius: 4px;
      @include breakpoint(medium up) {
        padding: 24px;
      }
    }

    &-main {
      display: block;

      @include breakpoint(large up) {
        display: grid;
        gap: 24px;
        grid-template-columns: auto 300px;
      }
    }

    &-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @include breakpoint(small up) {
        display: block;
        width: auto;
      }
    }

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      flex-wrap: wrap;

      time {
        font-size: 16px;
        line-height: 1.4;
        font-weight: 300;
        color: var(--light-grey);

        @include breakpoint(small up) {
          font-weight: 600;
        }
      }
    }

    &-btn {
      .button {
        border-radius: 100px;
        display: flex;
        gap: 7px;
        align-items: center;
        padding: 6px 10px 6px 18px;
        &:after {
          content: '';
          display: block;
          width: 28px;
          height: 28px;
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28' fill='none'%3E%3Cpath d='M14 0C6.2804 0 0 6.2804 0 14C0 21.7196 6.2804 28 14 28C21.7196 28 28 21.7196 28 14C28 6.2804 21.7196 0 14 0ZM14 25.8462C7.46803 25.8462 2.15385 20.532 2.15385 14C2.15385 7.46803 7.46803 2.15385 14 2.15385C20.532 2.15385 25.8462 7.46803 25.8462 14C25.8462 20.532 20.532 25.8462 14 25.8462Z' fill='white'%3E%3C/path%3E%3Cpath d='M17.2306 10.7582C17.2306 11.353 17.7128 11.8352 18.3075 11.8352C18.9023 11.8352 19.3845 11.353 19.3845 10.7582C19.3845 9.56559 18.7676 8.41888 17.692 7.61219C16.9573 7.06116 16.0533 6.69307 15.0768 6.53649V5.37355C15.0768 4.77881 14.5946 4.29663 13.9998 4.29663C13.4051 4.29663 12.9229 4.77881 12.9229 5.37355V6.53649C11.9463 6.69307 11.0423 7.06116 10.3077 7.61219C9.2321 8.41888 8.61523 9.56559 8.61523 10.7582C8.61523 11.9509 9.2321 13.0975 10.3077 13.9043C11.0424 14.4553 11.9464 14.8234 12.9229 14.98V19.2453C11.6823 18.9421 10.7691 18.137 10.7691 17.2198C10.7691 16.625 10.2869 16.1429 9.69216 16.1429C9.09741 16.1429 8.61523 16.625 8.61523 17.2198C8.61523 18.4124 9.2321 19.5591 10.3077 20.3658C11.0424 20.9169 11.9464 21.285 12.9229 21.4415V22.6153C12.9229 23.2101 13.4051 23.6922 13.9998 23.6922C14.5946 23.6922 15.0768 23.2101 15.0768 22.6153V21.4415C16.0534 21.285 16.9574 20.9169 17.692 20.3658C18.7676 19.5591 19.3845 18.4124 19.3845 17.2198C19.3845 16.0271 18.7676 14.8804 17.692 14.0737C16.9573 13.5227 16.0533 13.1546 15.0768 12.998V8.73276C16.3174 9.03603 17.2306 9.84106 17.2306 10.7582ZM10.7691 10.7582C10.7691 9.84106 11.6823 9.03595 12.9229 8.73276V12.7836C11.6823 12.4805 10.7691 11.6754 10.7691 10.7582ZM17.2306 17.2198C17.2306 18.137 16.3174 18.9421 15.0768 19.2453V15.1944C16.3174 15.4976 17.2306 16.3026 17.2306 17.2198Z' fill='white'%3E%3C/path%3E%3C/svg%3E")
            no-repeat center center / contain;
        }
      }
    }

    //problem-link
    &-prbl {
      display: flex;
      gap: 7px;
      align-items: center;
      text-decoration: none;
      color: var(--light-grey);
      font-weight: 700;
      text-transform: uppercase;
      &:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        margin-top: -2px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none'%3E%3Cpath d='M5.00065 9.16665C5.54793 9.16736 6.08996 9.0599 6.59557 8.85046C7.10119 8.64102 7.56043 8.33373 7.9469 7.94623C8.3344 7.55976 8.6417 7.10052 8.85114 6.5949C9.06058 6.08928 9.16803 5.54726 9.16732 4.99998C9.16802 4.45271 9.06056 3.91069 8.85112 3.40507C8.64168 2.89946 8.33439 2.44021 7.9469 2.05373C7.56043 1.66624 7.10119 1.35894 6.59557 1.1495C6.08996 0.940061 5.54793 0.832607 5.00065 0.833316C4.45338 0.832619 3.91136 0.940077 3.40574 1.14952C2.90013 1.35896 2.44088 1.66625 2.0544 2.05373C1.66692 2.44021 1.35963 2.89946 1.15019 3.40507C0.940749 3.91069 0.83329 4.45271 0.833988 4.99998C0.833279 5.54726 0.940732 6.08928 1.15017 6.5949C1.35961 7.10052 1.66691 7.55976 2.0544 7.94623C2.44088 8.33372 2.90013 8.64101 3.40574 8.85045C3.91136 9.05989 4.45338 9.16735 5.00065 9.16665V9.16665Z' stroke='%23D2D7E1' stroke-linejoin='round'%3E%3C/path%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.0013 7.70829C5.13943 7.70829 5.27191 7.65342 5.36959 7.55574C5.46726 7.45807 5.52213 7.32559 5.52213 7.18746C5.52213 7.04933 5.46726 6.91685 5.36959 6.81917C5.27191 6.7215 5.13943 6.66663 5.0013 6.66663C4.86317 6.66663 4.73069 6.7215 4.63302 6.81917C4.53534 6.91685 4.48047 7.04933 4.48047 7.18746C4.48047 7.32559 4.53534 7.45807 4.63302 7.55574C4.73069 7.65342 4.86317 7.70829 5.0013 7.70829Z' fill='%23D2D7E1'%3E%3C/path%3E%3Cpath d='M5 2.5V5.83333' stroke='%23D2D7E1' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E")
          no-repeat center center / contain;
      }
      &:hover {
        color: var(--light-grey);
        text-decoration: underline;
      }
    }

    &-rating {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: var(--light-grey);
      margin-bottom: 5px;
      flex-direction: column;
      gap: 4px;
      font-weight: 300;
      @include breakpoint(small up) {
        font-weight: 600;
        flex-direction: row;
        gap: 10px;
      }
    }

    &-info {
      &-lbl {
        font-weight: 600;
        font-size: 14px;
        color: var(--white);
        text-transform: uppercase;
      }

      &-val {
        color: var(--light-blue);
        font-weight: 600;
        font-size: 14px;
        a {
          transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
          transition-property: color, background-color, opacity;
          font-weight: 600;
          font-size: 14px;
          color: var(--light-blue);
          text-decoration: none;
          &:hover {
            color: #0a58ca;
          }
        }
      }

      &-box {
        display: flex;
        gap: 24px;
      }

      &-items {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 24px;
        flex-wrap: wrap;
        gap: 24px;
        @include breakpoint(small up) {
          justify-content: space-between;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2px;
        @include breakpoint(medium up) {
          flex-direction: row;
          gap: 7px;
        }
      }
    }

    .swiper-pagination {
      position: static;
      padding-top: 15px;
    }

    .swiper-pagination-bullet {
      border: 0;
      background: #fff;
      opacity: 0.2;
      transition: 0.4s;
      &-active {
        background: #11a9fe;
        opacity: 1;
      }
    }

    .iframe {
      iframe {
        height: 100%;
        width: 100%;
        border: none;
      }

      &-box {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 16/9;
      }

      &-img {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.38);
        backdrop-filter: blur(8px);
        transform: translateZ(0);
        z-index: 4;
      }

      &-buttons {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 7px;
        z-index: 5;
        .button {
          min-width: 130px;
        }
      }

      &.show {
        .iframe-bg,
        .iframe-buttons {
          display: none;
        }
      }
    }

    &-ttl {
      margin-bottom: 24px;
      margin-top: 24px;
      font-weight: 700;
      font-size: 22px;
      color: var(--white);
      text-align: center;
      @include breakpoint(large up) {
        text-align: start;
        margin-top: 0;
      }
    }

    &-items {
      display: block;
      @include breakpoint(large up) {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }

    &-item {
      min-height: 112px;
      position: relative;
      background: #041642;
      box-shadow: 0 4px 6px #000b25;
      border-radius: var(--radius-small);
      overflow: hidden;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-property: color, background-color, opacity;
      min-width: 300px;
      max-width: 300px;
      &:hover {
        background: #041c57;
        .hero-slot-item-ttl {
          color: var(--secondary-color);
        }
      }

      a {
        padding: 8px;
        text-decoration: none;
        position: absolute;
        min-height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 144px auto;
        gap: 12px;
        top: 0;
        left: 0;
        img {
          border-radius: var(--radius-small);
        }
      }

      &-line {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: var(--light-grey);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        margin-bottom: 8px;
      }

      &-ttl {
        color: var(--white);
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 10px;
        transition: 0.4s;
      }
    }
  }

  &-short {
    padding: 12px 0 32px;
    @include breakpoint(small down) {
      margin-right: 0;
      margin-left: 0;
    }

    & + .section {
      margin-top: 24px;
    }

    &-icon {
      bottom: 0;
      left: 1%;
      transform: translateY(50%);
      z-index: 3;
      position: absolute;
      width: 86px;

      @include breakpoint(xsmall down) {
        display: none;
      }
    }

    &-icon-top {
      position: absolute;
      top: 36px;
      right: 5%;
      width: 68px;
      @include breakpoint(xsmall down) {
        display: none;
      }
    }
  }
}
