.off-canvas {
  background: var(--hard-blue);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  z-index: 11;
  padding: 70px 15px 20px;
  transition: all var(--speed);

  &:not(.is-open) {
    transform: translateY(-100%);
  }

  .search {
    width: 100%;
    position: static;
  }
}
