.search {
  position: relative;
  z-index: 10;
  padding: 0 10px;
  gap: 10px;
  border-radius: var(--radius);
  display: flex;

  form {
    display: none;
    flex: 1;
    height: 100%;
  }

  input {
    background: transparent;
    color: var(--light-grey);
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    padding: 5px;
    height: 100%;
    border: none;
    outline: none;
  }

  &-point {
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.2803 19.2198L14.836 13.7753C16.0296 12.3441 16.75 10.5051 16.75 8.5C16.75 3.95088 13.0491 0.25 8.5 0.25C3.95088 0.25 0.25 3.95088 0.25 8.5C0.25 13.0491 3.95088 16.75 8.5 16.75C10.5051 16.75 12.3441 16.0296 13.7753 14.836L19.2198 20.2804C19.3662 20.4269 19.5582 20.5002 19.75 20.5002C19.9418 20.5002 20.1338 20.4269 20.2803 20.2804C20.5733 19.9874 20.5733 19.5126 20.2803 19.2198ZM1.75 8.5C1.75 4.77813 4.77775 1.75 8.5 1.75C12.2223 1.75 15.25 4.77813 15.25 8.5C15.25 12.2219 12.2223 15.25 8.5 15.25C4.77775 15.25 1.75 12.2219 1.75 8.5Z' fill='%23D2D7E1'%3E%3C/path%3E%3C/svg%3E");
  }

  &-close {
    display: none;
    opacity: 0;
    align-items: center;
    pointer-events: none;
    svg {
      fill: var(--white);
    }
  }

  ul {
    position: absolute;
    right: 0;
    top: 95%;
    background-color: #0e1449;
    width: calc(100% - 30px);
    list-style: none;
    padding: 2px 0;
    border-top: 1px solid var(--primary-color);
    margin: 0;
    max-height: 100px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      background: var(--black);
      width: 6px;
      &-thumb {
        background: var(--dark-grey);
        border-color: var(--dark-grey);
        border-radius: 4px;
      }
    }

    li {
      padding: 4px;
      &:hover {
        background-color: #151d68;
      }
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 15px;
        color: var(--light-grey);
        font-weight: 700;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  &.open {
    position: absolute;
    height: 48px;
    border: 1px solid var(--primary-color);
    width: var(--container-width);
    align-items: center;
    background-color: #0e1449;

    form {
      display: block;
    }

    .search-close {
      display: flex;
    }

    &.has-text {
      .search-close {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
